import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from "gatsby"

import Pointer from '../components/pointer.js'
import Header from '../components/header'
import cardProject from '../components/cardProject'
import TileProject from '../components/tileProject'
import '../layouts/index.css'

function is_touch_device() {
  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  var mq = function(query) {
    return window.matchMedia(query).matches;
  }

  if (('ontouchstart' in window)) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

var lastScrollX = 0;
var lastScrollY = 0;

class Layout extends Component {

   
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      x: 0,
      y: 0,
      alpha: 1.0
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onMouseMove = (event) => {
    if(is_touch_device()){
      return
    }

      const opacityMaxDistance = 10.0/100*document.documentElement.clientWidth;
      const opacityMinDistance = 2.0/100*document.documentElement.clientWidth;

      let x = event.pageX;
      let y = event.pageY;
      lastScrollX = window.scrollX;
      lastScrollY = window.scrollY;

      const mouseWindowX = event.clientX;
      const mouseWindowY = event.clientY;
      let alpha = 1.0;
      if(mouseWindowX < opacityMaxDistance) {
        alpha = (mouseWindowX-opacityMinDistance)/(opacityMaxDistance-opacityMinDistance);
      }
      if(mouseWindowY < opacityMaxDistance) {
        alpha = Math.min(alpha, (mouseWindowY-opacityMinDistance)/(opacityMaxDistance-opacityMinDistance));
      }
      if(mouseWindowX > (document.documentElement.clientWidth - opacityMaxDistance)){
        alpha = Math.min(alpha, (document.documentElement.clientWidth - opacityMinDistance - mouseWindowX)/(opacityMaxDistance-opacityMinDistance))
      }
      if(mouseWindowY > (document.documentElement.clientHeight - opacityMaxDistance)){
        alpha = Math.min(alpha, (document.documentElement.clientHeight - opacityMinDistance - mouseWindowY)/(opacityMaxDistance-opacityMinDistance))
      }
  
      this.setState({x, y, alpha})
  }

  onScroll = (event) => {
    if(is_touch_device()){
      return
    }
    let x = this.state.x;
    let y = this.state.y;
    x += window.scrollX - lastScrollX;
    y += window.scrollY - lastScrollY;
    lastScrollX = window.scrollX;
    lastScrollY = window.scrollY;
    this.setState({x: x, y: y});
  }

  render() {
    return (
  <div onMouseMove={this.onMouseMove}>
    <Pointer top={this.state.y} left={this.state.x} alpha={this.state.alpha} />
      <Header />
      {this.props.children}

  </div>
)
}
}

Layout.propTypes = {
      children: PropTypes.func,
  }

export default Layout