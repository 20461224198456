import React from 'react'
import styled, { keyframes } from 'styled-components';

const Circle = styled.div`
  position: absolute; 
  transform: translate(-50%,-50%);
  width: 40px;
  height: 40px; ;
  border: solid 1px #0065ff;
  border-radius: 50%;
  pointer-events: none;
  box-sizing: border-box;
  z-index: 1000000;
`
const SmallCircle = styled.div`
  position: absolute; 
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  width: 6px;
  height: 6px; 
  background-color: #0065ff;
  border-radius: 50%;
  pointer-events: none;
  box-sizing: border-box;
  z-index: 1000000;
`
const pointer = props => (
 		<Circle style={{top: props.top, left: props.left, opacity: props.alpha}}>
        <SmallCircle/>
 		</Circle>
)

export default pointer

