import React from 'react'
import Fade from 'react-reveal/Fade'
import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom';

const TileProjectContainer = styled.div`
  width: 100%;
  margin: 50px auto;
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: #051220;
  background-color: #051220;
  opacity: 1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`

const CardAnimation = keyframes`
  0% {
      opacity: 0;
      transform: translateY(-30px)
  }
  100% {
      opacity: 1;
      transform: translateY(0px)
  }
`

const ImageContainer = styled.div`
  margin: 8em 0em;
  border-radius: 4px;
  float: right;
  width: 100%;
  height: 10em;
`

const Image = styled.img`
  border-radius: 4px;
  position: relative;
  width: 150%;
  transition: all 0.15s ease;
  -webkit-transition: all 0.15s ease; /* Safari */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .11);
`

const TileProject = props => (
    <Fade bottom duration={1100} distance="8em" ssrFadeout>
    <div className="TileProjectContainer" >
    	<div className="ImageContainer">
            <img className="Image" src={props.image} />
    	</div>
    </div>
    </Fade>
)

export default TileProject