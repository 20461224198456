import React from 'react'
import Fade from 'react-reveal/Fade'
import Link from 'gatsby-link'
import './header.css';

class Header extends React.Component {
	
	constructor(props) {
	  super(props)

	  this.state = {
	    hasScrolled: false
	  }
	}

	componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
	}

	handleScroll = (event) => {
	    const scrollTop = window.pageYOffset

	    if (scrollTop > 50) {
	    this.setState({ hasScrolled: true })
	    } else {
	    this.setState({ hasScrolled: false })
	    }
	}

	render() {
    	return (
	 		<div>
	    		<div className={this.state.hasScrolled ? 'Header HeaderScrolled' : 'Header'}>
	      			<Fade top duration={650} distance="2em" ssrFadeout>
	      			<div className="nav">
	      				<p className="Logo"><Link className="logoLink" to="/">Alex Trimoulet</Link></p>
	       				<div className="menu">
	        				<Link className="navLink" to="/">Selected work</Link>
	        				<Link className="navLink" to="/work">More work</Link>
	        				<Link className="navLink" to="/sideprojects">Playground</Link>
	        				<Link className="navLink" to="/about">About</Link>
	      				</div>
	      			</div>
	      			</Fade>
	    		</div>
	 		</div>
   		)
  	}
}

export default Header
