import React from 'react'
import './cardProject.css'
import Fade from 'react-reveal/Fade'
import styled from 'styled-components'
import { Link } from 'react-router-dom';


const CardProject = props => (
    <div className="CardProject" >
        <div className="CardImageContainer">
            <img className="CardImage" src={props.image} />
        </div>
        <Fade bottom duration={550} delay={200} distance="1.6em" ssrFadeout>
    	<div className="CardText">
    		<p className="Caption">{props.caption}</p>
    		<p className="CardBigTitle">{props.title}</p>
            <p className="Role">{props.role}</p>
    		<a className="FakeLink">{props.fakelink} →</a>
            <a className="FakeLink2">{props.fakelink2}</a>
        
    	</div>
        </Fade>
    </div>

)

export default CardProject